import { graphql, Link } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import { BasicHeadTags } from "../components/SEO/BasicHeadTags";
import { content_wrapper } from "../styles/modules/content.module.css";

function Tematicas({ data }) {
  const { tematicPosts } = data;
  return (
    <Layout>
      <section className={content_wrapper}>
        <h1>
          Encuentra Paletas de colores para diferentes temáticas y propósitos
        </h1>
        <ul>
          {tematicPosts.edges.map(({ node }) => (
            <li key={node.id}>
              <a href={node.frontmatter.slug}>{node.frontmatter.title}</a>
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  );
}

export const Head = () => {
  return (
    <>
      <BasicHeadTags
        title="Encuentra Paletas de colores para diferentes temáticas y propósitos"
        description="Encuentra Paletas de colores para diferentes temáticas y propósitos"
        noindex={true}
      />
    </>
  );
};

export const query = graphql`
  query {
    tematicPosts: allMdx(
      filter: { frontmatter: { type: { eq: "tematicPost" } } }
      sort: { fields: frontmatter___date, order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
          }
        }
      }
    }
  }
`;

export default Tematicas;
